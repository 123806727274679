import { useQuery } from 'react-query';
import { getGraphQLClient } from '../graphql/graphqlClient';

import useYear from './useYear';
import GET_VIDEOS from '../graphql/queries/getVideosGql';

interface Video {
  id: string,
  title: string,
  urlVimeo: string,
  thumbnailSmall: string,
  thumbnailMedium: string,
  thumbnailLarge: string,
  year: number,
  position: number,
}

interface TransformedData {
  year: number;
  info: Video[];
}

export default function useVideos() {
  const graphQLClient = getGraphQLClient();
  const { year } = useYear();

  const { data: videoHome } = useQuery(
    ['VideosHome', year],
    async () => (graphQLClient && graphQLClient.request(GET_VIDEOS, { year })),
    {
      staleTime: Infinity,
    },
  );

  const data: TransformedData[] | undefined = videoHome?.videoHome?.nodes
    ?.reduce((acc: TransformedData[], videos: Video) => {
      const yearIndex = acc.findIndex((item) => item.year === videos.year);

      if (yearIndex !== -1) {
        acc[yearIndex].info.push(videos);
      } else {
        acc.push({ year: videos.year, info: [videos] });
      }

      return acc;
    }, []);

  return { data };
}
