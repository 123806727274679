export default function IconAlertHome() {
  return (
    <svg width="99" height="93" viewBox="0 0 99 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_12333_556)">
        <rect x="10" y="6" width="73" height="73" rx="36.5" fill="#E8E8E8" />
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
          <path d="M47.8195 63.8566C58.3608 63.8566 66.9062 63.6138 66.9062 63.3143C66.9062 63.0148 58.3608 62.772 47.8195 62.772C37.2783 62.772 28.7329 63.0148 28.7329 63.3143C28.7329 63.6138 37.2783 63.8566 47.8195 63.8566Z" fill="#716D6D" />
        </g>
        <path d="M30.1137 60.5151C28.3054 60.5151 26.6783 59.5904 25.7611 58.041C24.8439 56.4919 24.8206 54.6288 25.6987 53.0573L42.0852 23.7262C42.9886 22.1088 44.639 21.1431 46.5002 21.1431C48.3614 21.1431 50.0118 22.1088 50.9153 23.7262L67.3017 53.0573C68.1794 54.6288 68.1558 56.4919 67.2389 58.041C66.3217 59.59 64.6947 60.5151 62.8863 60.5151H30.1137Z" fill="white" />
        <path d="M26.6046 53.5575L42.9911 24.2261C44.5202 21.4891 48.4803 21.4891 50.0094 24.2261L66.3958 53.5575C67.8815 56.2171 65.9469 59.4849 62.8867 59.4849H30.1138C27.0536 59.4849 25.119 56.2171 26.6046 53.5575Z" fill="#D81E05" />
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.21">
          <path d="M48.0417 58.1703C52.5354 53.7177 57.0164 49.2528 61.4953 44.7855L56.1327 35.1865C52.37 38.9268 48.607 42.6671 44.8443 46.4078C40.7392 50.4888 36.6338 54.5695 32.5287 58.6502L31.6891 59.4848H46.7098C47.1531 59.046 47.5988 58.6095 48.0417 58.1703Z" fill="#BE0F0F" />
        </g>
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.43">
          <path d="M62.0175 45.7207C60.0476 47.6855 58.0799 49.6524 56.1079 51.6151C53.4691 54.2417 50.8271 56.8652 48.1816 59.4848H62.8867C65.9469 59.4848 67.8815 56.217 66.3958 53.5575L62.0178 45.7207H62.0175Z" fill="#AC0404" />
        </g>
        <path d="M43.8738 48.2735L42.8212 36.6947V36.4763C42.8212 35.9214 43.2736 35.4717 43.8318 35.4717H49.1683C49.7266 35.4717 50.179 35.9214 50.179 36.4763V36.6947L49.1031 48.2753C49.0573 48.7689 48.6408 49.1464 48.1422 49.1464H44.8347C44.3354 49.1464 43.9186 48.7678 43.8734 48.2732L43.8738 48.2735ZM43.707 53.7329V51.2633C43.707 50.8374 44.0543 50.4921 44.4828 50.4921H48.4927C48.9211 50.4921 49.2685 50.8374 49.2685 51.2633V53.7329C49.2685 54.1588 48.9211 54.5041 48.4927 54.5041H44.4828C44.0543 54.5041 43.707 54.1588 43.707 53.7329Z" fill="white" />
        <path d="M88.0953 62.8936C88.6593 63.7836 88.0483 64.9504 86.9955 64.9938L75.1519 65.4821C74.0991 65.5256 73.3941 64.413 73.8829 63.4796L79.3818 52.9785C79.8706 52.0451 81.1866 51.9908 81.7505 52.8808L88.0953 62.8936Z" fill="#E8E8E8" />
      </g>
      <defs>
        <filter id="filter0_d_12333_556" x="0" y="0" width="98.3098" height="93" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12333_556" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12333_556" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
