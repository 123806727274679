import { gql } from 'graphql-request';

const GET_BANNERS = gql`
  query MyQuery($year: Int) {
    banners(year: $year) {
      nodes {
        id
        title
        subtitle
        imageUrl
        customLink
        customLinkName
        employeeType
        secondaryCustomLink
        secondaryCustomLinkName
        bannerFileUrl
        bannerFileName
        year
        position
        cardType
      }
    }
  }
`;

export default GET_BANNERS;
