import Styled from 'styled-components';

const StyledLogoIncentive = Styled.img`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 84px;
  @media screen and (min-width: 768px) {
    top: 0.5rem;
    max-width: 120px;
  }
`;

export default StyledLogoIncentive;
