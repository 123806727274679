import { Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Button from '../../atoms/Button/Button';
import InputField from '../../atoms/Field/StyledField';
import Error from '../../atoms/Error/StyledError';
import Spinner from '../../atoms/Spinner/Spinner';
import CREATE_SESSION_GQL from '../../../graphql/mutations/createSessionGql';
import { Container, FormContainer, FormLogin } from '../../organisms/Login/StyledForm';
import initialValues from '../../organisms/Login/InitialValues';
import validationSchema from '../../organisms/Login/ValidationSchema';
import sessionStore from '../../../store/sessionStore';

type SigninUserValues = {
  email: string;
  password: string;
}

export default function MirrorSignin() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [redirect, setRedirect] = useState<boolean>(false);
  const { setSession } = sessionStore();

  const createSession = (values: SigninUserValues) => fetch(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: CREATE_SESSION_GQL,
      variables: {
        email: values.email,
        password: values.password,
      },
    }),
  })
    .then((response) => response.json())
    .then((result) => result);

  const { mutate } = useMutation(createSession, {
    onSuccess: (data) => {
      queryClient.setQueryData(['user'], data?.data?.signinUser);
      const token = data?.data?.signinUser?.token;

      if (token && token !== undefined) {
        setSession(true, token);
        setRedirect(true);
      }

      if (token === null) {
        setErrorMessage('Credenciales incorrectas');
      }
    },
  });

  if (redirect) {
    setTimeout(() => {
      navigate('/', { replace: true });
      window.location.reload();
    }, 100);
  }

  return (
    <Container>
      <FormContainer>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            mutate(values as SigninUserValues);
            actions.setSubmitting(false);
            actions.resetForm({ values: initialValues });
          }}
        >
          {({
            errors, touched, isSubmitting,
          }) => (
            <FormLogin>
              <InputField
                name="email"
                type="text"
                placeholder="Email"
                autoComplete="off"
                className={errors.email && touched.email ? 'error' : ''}
              />
              {errors.email && touched.email ? (
                <Error>{errors.email}</Error>
              ) : null}
              <InputField
                name="password"
                type="password"
                placeholder="Contraseña"
                autoComplete="off"
                className={errors.password && touched.password ? 'error' : ''}
              />
              {errors.password && touched.password ? (
                <Error>{errors.password}</Error>
              ) : errorMessage ? (<Error>{errorMessage}</Error>) : null}
              <Button type="submit" size={262} disabled={isSubmitting}>
                {!isSubmitting ? (
                  'Entrar'
                ) : (
                  <Spinner background="rgba(200,200,200,0.6)" color="#fff" />
                )}
              </Button>
            </FormLogin>
          )}
        </Formik>
      </FormContainer>
    </Container>
  );
}
