import ScrollContainer from 'react-indiana-drag-scroll';
import {
  StyledBannerCard,
  StyledBannerCarousel,
  StyledBannerImage,
  StyledBannerImageWrapper,
  StyledBannerItem,
  StyledBannerList,
  StyledBannerOverlay,
  StyledFooterBanner,
  StyledBannerTitle,
  StyledBannerWrapLinks,
  StyledBannerLinks,
} from '../../organisms/Home/StyledHome';
import { Banner, BannerData } from '../../../interfaces/Banners';
import useYear from '../../../hooks/useYear';
import { Card } from '../Card/Card';
import LinkNavigate from '../Link/LinkNavigate';
import Theme from '../../../styles/Theme';

type BannerProps = {
  data: Array<BannerData>;
}

export default function BannerList(props: BannerProps) {
  const { data } = props;
  const { year } = useYear();
  const dataFiltered = data
    ?.filter((item: BannerData) => item.year === year)[0]
    ?.info.sort((a: { position?: number | null }, b: { position?: number | null }) => {
      if (a.position == null && b.position != null) return 1;
      if (b.position == null && a.position != null) return -1;
      return (a.position || 0) - (b.position || 0);
    });

  const getcolor = (type: string) => {
    switch (type) {
    case 'Campaign':
      return Theme.colorCampaign;
    case 'Rappel':
      return Theme.colorRappel;
    case 'Convention':
      return Theme.colorConvention;
    case 'Ranking':
      return Theme.colorRanking;
    default:
      return Theme.colorCampaign;
    }
  };

  return (
    <StyledBannerCarousel>
      <StyledBannerList>
        <ScrollContainer className="container">
          {dataFiltered?.map((item: Banner) => (
            <StyledBannerItem key={item?.id}>
              <StyledBannerCard color={getcolor(item?.cardType)}>
                <StyledBannerImageWrapper>
                  <StyledBannerImage src={item?.imageUrl} alt={item?.title} />
                  <StyledBannerOverlay className="overlay" color={getcolor(item?.cardType)} />
                </StyledBannerImageWrapper>
                <StyledFooterBanner className="footer-banner" color={getcolor(item?.cardType)}>
                  <StyledBannerTitle className="title" color={getcolor(item?.cardType)}>
                    {item?.title}
                  </StyledBannerTitle>
                  <StyledBannerLinks>
                    <LinkNavigate
                      customLink={`${item?.customLink}`}
                      customLinkName={item?.customLinkName ? item?.customLinkName : ''}
                      secondaryCustomLink=""
                      secondaryCustomLinkName=""
                      color={getcolor(item?.cardType)}
                    />
                    {item?.bannerFileUrl && item?.bannerFileName && item?.bannerFileUrl !== '#' && (
                      <Card background="transparent" display="flex" flexDirection="column">
                        <a href={`${item?.bannerFileUrl}`} target="_blank" className="card__link" rel="noreferrer">
                          {item?.bannerFileName}
                        </a>
                      </Card>
                    )}
                  </StyledBannerLinks>
                  <StyledBannerWrapLinks className="links">
                    <Card background="transparent" display="flex" flexDirection="column">
                      <LinkNavigate
                        customLink=""
                        customLinkName=""
                        secondaryCustomLink={item?.secondaryCustomLink}
                        secondaryCustomLinkName={item.secondaryCustomLinkName ? item.secondaryCustomLinkName : 'Seguimiento planes'}
                        color={getcolor(item?.cardType)}
                      />
                    </Card>
                  </StyledBannerWrapLinks>
                </StyledFooterBanner>
              </StyledBannerCard>
            </StyledBannerItem>
          ))}
        </ScrollContainer>
      </StyledBannerList>
    </StyledBannerCarousel>
  );
}
