import { useMemo } from 'react';

import HomeCarousel from '../HomeCarousel/HomeCarousel';

export default function HomeAnnouncements(props: any) {
  const { data, year } = props;

  const dataFiltered = useMemo(() => {
    if (data === undefined) return [];
    return data?.filter((item: any) => item.year === year)?.[0]
      ?.info?.sort((a: any, b: any) => a.position - b.position) || [];
  }, [data, year]);

  return (
    <div>
      {!(dataFiltered.length === 0) && (
        <HomeCarousel
          items={dataFiltered}
          backgroundImage="https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/home/bannerNotification2025.png"
        />
      )}
    </div>
  );
}
