import React, { useState, useEffect } from 'react';
import './HomeCarousel.css';
import IconPrevious from '../../atoms/Icon/IconPrevious';
import IconNext from '../../atoms/Icon/IconNext';
import IconAlertHome from '../../atoms/Icon/IconAlertHome';

interface CarouselProps {
  items: any;
  backgroundImage: string;
}

function HomeCarousel({ items, backgroundImage }: CarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState<number | null>(null);
  const [animationDirection, setAnimationDirection] = useState<'left' | 'right'>('left');

  const changeSlide = (direction: 'left' | 'right') => {
    if (nextIndex !== null) return;

    const newIndex = direction === 'right'
      ? (currentIndex + 1) % items.length
      : (currentIndex - 1 + items.length) % items.length;

    setAnimationDirection(direction);
    setNextIndex(newIndex);
    setTimeout(() => {
      setCurrentIndex(newIndex);
      setNextIndex(null);
    }, 500);
  };

  const goToIndex = (index:any) => {
    setAnimationDirection('right');
    setNextIndex(index);
    setTimeout(() => {
      setCurrentIndex(index);
      setNextIndex(null);
    }, 500);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      changeSlide('right');
    }, 10000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const goToPrev = () => {
    changeSlide('left');
  };

  const goToNext = () => {
    changeSlide('right');
  };

  const replaceAttachments = (html: string) => {
    const regexIgnore = /<action-text-attachment.*?url="(.*?)".*?>☒<\/action-text-attachment>/g;
    const regexExtract = /<action-text-attachment.*?url="(.*?)".*?>(?:<figure.*?>.*?<\/figure>)?.*?<figcaption.*?>(.*?)<\/figcaption>.*?<\/action-text-attachment>/gs;

    const htmlWithUpdatedUrls = html.replace(regexIgnore, (_, url) => ` <a href="${url}" target="_blank">pincha aquí</a> `);
    return htmlWithUpdatedUrls.replace(regexExtract, (_, url) => ` <a href="${url}" target="_blank">pincha aquí</a> `);
  };

  return (
    <>
      <div className="carousel" style={{ backgroundImage: `url(${backgroundImage})`, maxHeight: '300px' }}>
        <div className="carousel-content">
          <button type="button" className="arrow-button left" onClick={goToPrev}>
            <IconPrevious color="white" />
          </button>
          <div className="carousel-card-container">
            <div
              className={`carousel-card ${
                nextIndex !== null ? `slide-out-${animationDirection}` : ''
              }`}
            >
              <div className="icon-top-right"><IconAlertHome /></div>
              <div
                className="carousel-title"
                dangerouslySetInnerHTML={{ __html: replaceAttachments(items[currentIndex].body) }}
              />
              {items[currentIndex].customLink && (
                <span>
                  <a className="carousel-button" href={items[currentIndex].customLink} target="_blank" rel="noreferrer">{items[currentIndex].customLinkName}</a>
                </span>
              )}
              {items[currentIndex].notificationFileUrl && items[currentIndex].notificationFileUrl !== '#' && (
                <span>
                  <a className="carousel-button" href={items[currentIndex].notificationFileUrl} target="_blank" rel="noreferrer">{items[currentIndex].notificationFileName}</a>
                </span>
              )}
            </div>

            {nextIndex !== null && (
              <div className={`carousel-card slide-in-${animationDirection}`}>
                <div className="icon-top-right"><IconAlertHome /></div>
                <div
                  className="carousel-title"
                  dangerouslySetInnerHTML={{ __html: replaceAttachments(items[nextIndex].body) }}
                />
                {items[nextIndex].customLink && (
                  <span>
                    <a className="carousel-button" href={items[nextIndex].customLink} target="_blank" rel="noreferrer">{items[nextIndex].customLinkName}</a>
                  </span>
                )}
                {items[nextIndex].notificationFileUrl && items[nextIndex].notificationFileUrl !== '#' && (
                  <span>
                    <a className="carousel-button" href={items[nextIndex].notificationFileUrl} target="_blank" rel="noreferrer">{items[nextIndex].notificationFileName}</a>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <button type="button" className="arrow-button right" onClick={goToNext}>
          <IconNext color="white" />
        </button>
      </div>
      <div className="carousel-indicators">
        {items.map((item:any, index:any) => (
          <button
            key={item.id}
            type="button"
            className={`indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToIndex(index)}
            aria-label={`Ir a ${item.id}`}
            aria-pressed={index === currentIndex}
          />
        ))}
      </div>
    </>
  );
}

export default HomeCarousel;
