import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const Menu = Styled.ul`
  color: ${Theme.colorSecondary};
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.25rem;
  overflow: auto;
  align-items: start;
  width: 100%;
`;

export const SubMenu = Styled.ul`
  background-color: ${Theme.colorSecondary2};
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  overflow: auto;
  margin: 0 0 0.5rem;

  @media screen and (min-width: 768px) {
    margin: 0;
  }
`;

export const SubMenuItem = Styled.li`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex-grow: 1;
  gap: 0.25rem;

  a {
    background-color: ${Theme.colorSecondary2};
    color: ${Theme.colorSecondary};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.82rem;
    min-width: 104px;
    height: 50px;

    &.active {
      box-shadow: inset 0px -4px ${Theme.colorPrimary};
      font-weight: 500;
    }
  }

  span {
    font-size: 13px;
  }

  svg {
    width: 19px;
    height: 19px;
  }

  @media screen and (min-width: 600px) {
    flex-grow: initial;

    a {
      min-width: 120px;
    }
  }
`;

export const MenuItem = Styled.li`
  background-color: ${Theme.colorTertiary};

  > a {
    display: flex;
    justify-content: center;
    min-width: 170px;
    font-size: 15px;

    &.active {
      background-color: ${Theme.colorSecondary2};
      color: ${Theme.colorPrimary};
      font-weight: 500;
    }
  }

  @media screen and (min-width: 600px) {
    > a {
      min-width: 174px;
    }
  }
`;

export const MainSubMenu = Styled.nav`
  @media screen and (min-width: 768px) {
    background-color: ${Theme.colorSecondary2};
    display: flex;
    height: 50px;
  }
`;

export const Hamburger = Styled.button`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const ContainerHelp = Styled.a`
  background-color: ${Theme.colorSecondary};
  color: ${Theme.colorWhite};
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: 0.5rem;
  width: 100%;

  span {
    display: inline-block;
    text-align: right;
  }

  @media screen and (min-width: 768px) {
    max-width: 206px;
    margin-left: 8px;
    border-radius: 5px;
    height: 42px;
    align-self: center;

    svg {
      width: 28px;
      height: 28px;
    }
  }
`;

export const StyledRedeem = Styled.a`
  background-color: ${Theme.colorPrimary};
  color: ${Theme.colorWhite};
  font-size: 14px;
  padding: 0.32rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: 0.5rem;
  width: 100%;
  text-align: center;

  @media screen and (min-width: 768px) {
    margin-left: auto;
    max-width: 200px;
    height: 42px;
    align-self: center;
    border-radius: 5px;
    padding: 0.4rem 2rem;
  }
`;
