import { gql } from 'graphql-request';

const GET_HOME_NOTIFICATION_GQL = gql`
  query homeNotifications($year: Int, $promote: Boolean, $activeUntil: String) {
    homeNotifications(year: $year, promote: $promote, activeUntil: $activeUntil) {
      nodes {
        activeUntil
        body
        customLinkName
        customLink
        notificationFileUrl
        notificationFileName
        id
        promote
        title
        updatedAt
        year
        position
      }
    }
  }
`;

export default GET_HOME_NOTIFICATION_GQL;
